import { hexToRgb } from 'assets/jss/material-dashboard-pro-react';
import styles from 'assets/jss/material-dashboard-pro-react/components/campaignStepsStyle';
import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import { ETouchType } from 'types/touch-type';

import { ButtonBase, Tooltip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import EmailIcon from '@material-ui/icons/Email';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import {
  Timeline, TimelineConnector, TimelineContent, TimelineDot,
  TimelineItem, TimelineSeparator,
} from '@material-ui/lab';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import EditCustomTouchTemplateModal from 'components/CampaignSteps/CustomTouches/EditCustomTouchTemplateModal';
import PreviewEmailModal from 'components/CampaignSteps/PreviewEmailModal';
import PreviewPostcardLetterModal from 'components/CampaignSteps/PreviewPostcardLetterModal';
import DashboardBubble from 'components/DashboardBubble/DashboardBubble';
import { useDataFilterContext } from 'components/DataMap/DataFilterProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';

import { getTouchPreview, getVoicemails } from 'utils/api';
import {
  getColorForTouchType, getIconForTouchType, isCampaignUsingCalendarBasedSystem,
  isCrmProEventType, showAPIErrorAlert,
} from 'utils/lib';

const useStyles = makeStyles(styles);

const CampaignSteps = ({
  agent, eventType, initialTouchTriggers, campaignStartDate, campaignCreatedDate,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isAdmin, isAnyAgent } = useAuthDataContext();
  const { setCurrentAlert } = useAlertContext();
  const { availableEventTypes } = useDataFilterContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const [touchTriggers, setTouchTriggers] = React.useState(initialTouchTriggers);
  const [touchCards, setTouchCards] = React.useState(null);

  const [isEditing, setIsEditing] = React.useState(null);
  const [isPreviewing, setIsPreviewing] = React.useState(null);

  const [eventTypeVoicemails, setEventTypeVoicemails] = React.useState(null);

  const reinsertTouchTrigger = (editedTouchTrigger) => {
    // Reinsert at same index
    const index = touchTriggers.findIndex(({ _id }) => _id === editedTouchTrigger._id);
    const newTouchTriggers = [...touchTriggers];
    newTouchTriggers.splice(index, 1, editedTouchTrigger);
    setTouchTriggers(newTouchTriggers);
  };

  React.useEffect(() => {
    (async () => {
      if (!availableEventTypes) return;

      try {
        const { voicemails } = await getVoicemails();

        setEventTypeVoicemails(voicemails.filter((voicemail) => voicemail.eventType === eventType._id));
      } catch (err) {
        console.error(err);
        showAPIErrorAlert(setCurrentAlert, err);
      }
    })();
  }, [availableEventTypes]);

  React.useEffect(() => {
    (async () => {
      if (!agent || !eventType || !touchTriggers) return;

      let nextTouchToGoOutFound = false;

      setTouchCards(touchTriggers.map((touchTrigger) => {
        const {
          _id, description, processAt, sendAt, processedAt, processing, touchType: { name: touchTypeName }, customTouchTemplate, metadata,
        } = touchTrigger;

        let { processed } = touchTrigger;

        // Special handling for CRM Pro birthday touch triggers
        if (isCrmProEventType(eventType) && description.toLowerCase().includes('birthday')) {
          processed = false;
        }

        const nextTouchToGoOut = !nextTouchToGoOutFound && !processed;
        if (nextTouchToGoOut) nextTouchToGoOutFound = true;

        let leftContent = null;
        let rightContent = null;

        switch (touchTypeName) {
          case 'VOICEMAIL': {
            const { voicemail, subject } = metadata;

            if (voicemail) {
              leftContent = (
                <>
                  <h4>{voicemail.name}</h4>
                  {voicemail.archived && <h5>This recording is archived, but it will still be used for this touch unless replaced.</h5>}
                </>
              );

              rightContent = (
                <audio
                  src={voicemail.recordingUrl}
                  type="audio/mpeg"
                  controls
                  preload="auto"
                />
              );
            } else if (!touchTrigger.processed) {
              leftContent = (
                <DashboardBubble
                  rootClass={classes.campaignDatesMessage}
                  backgroundColor={`rgb(${hexToRgb(theme.palette.warning.main)}, 0.1)`}
                  icon={<WarningRoundedIcon htmlColor={theme.palette.warning.main} />}
                  content="You don't have an audio recording assigned to this voicemail touch. Click the edit button to assign or record one."
                />
              );
            } else {
              leftContent = (
                <DashboardBubble
                  rootClass={classes.campaignDatesMessage}
                  backgroundColor={`rgb(${hexToRgb(theme.palette.warning.main)}, 0.1)`}
                  icon={<WarningRoundedIcon htmlColor={theme.palette.warning.main} />}
                  content="There was no voicemail recording assigned to this touch."
                />
              );
            }

            leftContent = (
              <>
                {leftContent}
                <h4 style={{ marginTop: '16px' }}>{`Email Subject: ${customTouchTemplate?.extraMergeVariables.customEmailSubjectWithMergeVariables ?? subject}`}</h4>
              </>
            );

            break;
          }
          case 'EMAIL': {
            const { subject } = metadata;
            leftContent = <h4>{customTouchTemplate?.extraMergeVariables.customEmailSubjectWithMergeVariables ?? subject}</h4>;
            break;
          }
          case 'LETTER': {
            leftContent = <div />;
            break;
          }
          case 'POSTCARD': {
            leftContent = <div />;
            break;
          }
          case 'TEXT': {
            break;
          }
          default: {
            break;
          }
        }

        return {
          touchTrigger,
          nextTouchToGoOut,
          processed,
          touchTypeName,
          leftContent,
          rightContent,
          sendAt,
          processAt,
        };
      }));
    })();
  }, [agent, eventType, touchTriggers]);

  const sendEmailPreview = async (touchTrigger) => {
    showLoadingIndicatorModal();

    try {
      await getTouchPreview(touchTrigger._id, { sendEmailPreview: true });

      setCurrentAlert('success', 'You should receive a sample email in your mailbox shortly.');
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  if (!availableEventTypes || !eventTypeVoicemails || !touchCards) return <LoadingIndicator />;

  const hasIntroEmailTouch = !!touchTriggers.find((o) => o.description.includes('Email #0'));

  let activeModal = null;
  if (isEditing || isPreviewing) {
    let touchTrigger = isEditing;
    if (!touchTrigger) touchTrigger = isPreviewing;

    const { touchType: { name: touchTypeName }, metadata } = touchTrigger;

    switch (touchTypeName) {
      case 'VOICEMAIL': {
        // NOTE: We handle voicemail fallback email previews in a useEffect hook above

        if (isEditing) {
          activeModal = (
            <EditCustomTouchTemplateModal
              eventTypeVoicemails={eventTypeVoicemails}
              touchTrigger={touchTrigger}
              onReceiveEditedTouchTrigger={reinsertTouchTrigger}
              onClose={() => setIsEditing(null)}
            />
          );
        } else if (isPreviewing) {
          activeModal = (
            <PreviewEmailModal
              onClose={() => setIsPreviewing(null)}
              touchTrigger={touchTrigger}
              touchName={`${touchTrigger.description.split(' - ')[1]}`} // Description form is 'Campaign ID - Email #1'
            />
          );
        }

        break;
      }
      case 'EMAIL': {
        // NOTE: We handle email previews in a useEffect hook above

        if (isEditing) {
          activeModal = (
            <EditCustomTouchTemplateModal
              touchTrigger={touchTrigger}
              onReceiveEditedTouchTrigger={reinsertTouchTrigger}
              onClose={() => setIsEditing(null)}
            />
          );
        } else if (isPreviewing) {
          activeModal = (
            <PreviewEmailModal
              onClose={() => setIsPreviewing(null)}
              touchTrigger={touchTrigger}
              touchName={`${touchTrigger.description.split(' - ')[1]}`} // Description form is 'Campaign ID - Email #1'
            />
          );
        }

        break;
      }
      case 'LETTER': {
        if (isEditing) {
          activeModal = (
            <EditCustomTouchTemplateModal
              touchTrigger={touchTrigger}
              onReceiveEditedTouchTrigger={reinsertTouchTrigger}
              onClose={() => setIsEditing(null)}
            />
          );
        } else if (isPreviewing) {
          activeModal = (
            <PreviewPostcardLetterModal
              onClose={() => setIsPreviewing(null)}
              touchTrigger={touchTrigger}
              touchName={`${touchTrigger.description.split(' - ')[1]}`} // Description form is 'Campaign ID - Email #1'
            />
          );
        }

        break;
      }
      case 'POSTCARD': {
        if (isEditing) {
          activeModal = (
            <EditCustomTouchTemplateModal
              touchTrigger={touchTrigger}
              onReceiveEditedTouchTrigger={reinsertTouchTrigger}
              onClose={() => setIsEditing(null)}
            />
          );
        } else if (isPreviewing) {
          activeModal = (
            <PreviewPostcardLetterModal
              onClose={() => setIsPreviewing(null)}
              touchTrigger={touchTrigger}
              touchName={`${touchTrigger.description.split(' - ')[1]}`} // Description form is 'Campaign ID - Email #1'
            />
          );
        }

        break;
      }
      case 'TEXT': {
        break;
      }
      default: {
        break;
      }
    }
  }

  return (
    <>
      {activeModal}

      <Timeline className={classes.timeline}>
        {touchCards.map((touchCard, i) => {
          const {
            touchTrigger, nextTouchToGoOut, processed, touchTypeName, leftContent, rightContent, sendAt, processAt,
          } = touchCard;

          const Icon = getIconForTouchType(touchTypeName);

          const isMailOut = touchTypeName === 'POSTCARD' || touchTypeName === 'LETTER';

          let touchTitle;

          // We check for campaign start date in various places here because it isn't provided for draft campaigns

          const touchNumber = hasIntroEmailTouch ? i : i + 1;

          if (touchTrigger.description.includes('Email #0')) {
            // Intro email check
            touchTitle = `Introductory Email Touch - ${moment(sendAt).format(campaignStartDate ? 'MMMM Do, YYYY' : 'MMMM Do')}`;
          } else if (campaignStartDate) {
            touchTitle = `Touch ${touchNumber} - Day ${moment(sendAt).diff(moment(campaignStartDate), 'days') + 1} - ${moment(sendAt).format('MMMM Do, YYYY')}`;
          } else if (isCampaignUsingCalendarBasedSystem(eventType.name, campaignCreatedDate)) {
            touchTitle = `Touch ${touchNumber} - ${moment(sendAt).format('MMMM Do')}`;
          } else {
            touchTitle = `Touch ${touchNumber}`;
          }

          return (
            <TimelineItem key={i}>
              <TimelineSeparator>
                <TimelineDot>
                  <div className={cx(nextTouchToGoOut && classes.timelineIconContainerBlink)}>
                    <div
                      className={classes.timelineIconContainer}
                      style={{
                        backgroundColor: processed || nextTouchToGoOut ? getColorForTouchType(touchTypeName) : theme.palette.grayScale10.main,
                      }}
                    >
                      <Icon htmlColor={processed || nextTouchToGoOut ? theme.palette.white.main : theme.palette.gray.main} />
                    </div>
                  </div>
                </TimelineDot>

                {/* Don't draw a connector on the last one */}
                {i !== touchCards.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent className={classes.touchCardContainer}>
                <h3>{touchTitle}</h3>
                <h4>
                  {/* Description form is 'Campaign ID - Email #1' */}
                  {`${touchTrigger.description.split(' - ')[1]}`}
                  {touchTrigger.customTouchTemplate && <span>{` (Customized ${touchTrigger.customTouchTemplate.type === 'BODY_TEXT' ? 'Text' : 'Design'})`}</span>}
                  {campaignStartDate ? ` - ${processed ? 'Sent' : 'Not Sent'}` : ''}
                  {isMailOut && campaignStartDate ? ` - ${processed ? 'Mailed' : 'Mailing'} Out On ${moment(processAt).format('MMMM Do, YYYY')}` : ''}
                </h4>

                <div className={classes.touchCardRow}>
                  <div className={classes.touchCardRowLeft}>
                    {leftContent}
                  </div>
                  <div className={classes.touchCardRowRight}>
                    {rightContent}
                    <div className={classes.actions}>
                      {/* Admins/brokerage admins can't edit touches */}
                      {!touchTrigger.processing && isAnyAgent && (
                        <Tooltip
                          title="Edit"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <ButtonBase onClick={() => setIsEditing(touchTrigger)}>
                            <EditRoundedIcon htmlColor="#838391" />
                          </ButtonBase>
                        </Tooltip>
                      )}

                      {/* Let admins preview emails by receiving an actual test email */}
                      {/* Don't show the button for old VMs that didn't support fallback emails */}
                      {isAdmin && (touchTypeName === ETouchType.Email || (touchTypeName === 'VOICEMAIL' && touchTrigger.metadata.subject)) && (
                        <Tooltip
                          title="Receive Test Email"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <ButtonBase onClick={() => sendEmailPreview(touchTrigger)}><EmailIcon htmlColor="#838391" /></ButtonBase>
                        </Tooltip>
                      )}

                      {/* Don't show the preview button for old VMs that didn't support fallback emails */}
                      {!(touchTypeName === 'VOICEMAIL' && !touchTrigger.metadata.subject) && (
                        <Tooltip
                          title={touchTypeName !== 'VOICEMAIL' ? 'Preview' : 'Preview Email'}
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <ButtonBase onClick={() => setIsPreviewing(touchTrigger)}><VisibilityRoundedIcon htmlColor="#838391" /></ButtonBase>
                        </Tooltip>
                      )}

                    </div>
                  </div>
                </div>
                <div className={classes.touchCardRowDivider} />
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </>
  );
};

export default CampaignSteps;
